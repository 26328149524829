<div id="divToExport" *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire.indexOf( 'ordonnance') > -1&&medcin">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
    <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet">
    <style>
        .bold-line {
            border-top: 3px solid;
        }

        .letter-spacing {
            letter-spacing: 3px;
        }

        ul {
            list-style-type: none;
        }

        .main-contenu {
            padding-left: 3.2rem !important;
            height: 800px;
        }

        .contenu {
            font-size: 1rem;
            font-weight: 600;
        }

        #footer-cont {
            position: fixed;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            box-sizing: border-box;
            background-color: #fff;
            height: 150px;
        }

        .header {
            height: 200px;
        }

        .flex-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100vh;
        }

        .logo-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }
    </style>
    <div class="flex-wrapper">
        <!-- En-tête -->
        <app-header-template></app-header-template>
        <!-- Content-->
        <div class=" container mt-4 ml-7 main-content" id="main-cont">
            <h2 class="text-center" [style.color]="medcin.colorTemplates"><strong>ORDONNANCE </strong></h2>
            <div *ngFor="let drug of myExamen.foundImpression.listDrugs">
                <h5 class="text-left"><strong>{{drug.nom}}</strong> </h5>
                <p>{{drug.duree}}, {{drug.posology}}<span *ngIf="drug.oeilDroit&&!drug.oeilGauche">, oeil droit</span> <span *ngIf="!drug.oeilDroit&&drug.oeilGauche">, oeil gauche</span> <span *ngIf="drug.oeilDroit&&drug.oeilGauche">, les 2 yeux</span></p>
            </div>
            <p>{{myExamen.foundImpression.consigne}} </p>
            <p>{{myExamen.foundImpression.informationAdditionnel}} </p>
        </div>
        <!-- End Content-->
        <!-- Pied de page  -->
        <app-footer-template></app-footer-template>

        <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.4/dist/umd/popper.min.js"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>

    </div>
</div>