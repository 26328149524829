import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AnomalieService } from 'src/app/services/anomalie/anomalie.service';
import { Chart } from 'chart.js';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-refraction',
  templateUrl: './refraction.component.html',
  styleUrls: ['./refraction.component.css', '../examen.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(500, style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class RefractionComponent implements OnInit {

  @Input() todayExamenRef: boolean
  @Input() showRefSubjective: boolean
  @Input() showAcuite: boolean

  @Output() sendAcuitValues: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sendRefValues: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sendTodayExam: EventEmitter<boolean> = new EventEmitter<boolean>();

  refObjavecCyc = false
  refObjsansCyc = true;
  acuiteVisLoin = true;
  acuiteVisPres = false;
  avecCycAtropine = false
  avecCycCyclocol = false
  avecCycTropicamide = false
  // echelle Monoyer
  echelleMonoyer = true
  echelleMonoyerList = ["10/10", "9/10", "8/10", "7/10", "6/10", "5/10", "4/10", "3/10", "2/10", "1/10", "CLD à 3m", "CLD à 2m", "CLD à 1m", "VBLM", "PLMO", "PLBO"]
  MonoyerAVSCLeft: any
  MonoyerAVSCRight: any
  MonoyerAVACLeft: any
  MonoyerAVACRight: any
  // echelle snellen
  echelleSnellen = false
  echelleAcuiteLoinHist = ["20/10", "20/15", "20/20", "10/10", "9/10", "8/10", "20/25", "7/10", "6/10", "20/40", "5/10", "4/10", "3/10", "2/10", "1/10", "20/80", "20/200", "20/400", "CLD à 3m", "CLD à 2m", "CLD à 1m", "VBLM", "PLMO", "PLBO"]
  echelleList1 = ["PLBO", "PLMO", "VBLM", "CLD à 1m", "CLD à 2m", "CLD à 3m", "20/400", "20/200", "20/80", "1/10", "2/10", "3/10", "4/10", "5/10", "20/40", "6/10", "7/10", "20/25", "8/10", "9/10", "10/10", "20/20", "20/15", "20/10"]
  echelleSnellenList = ["20/10", "20/15", "20/20", "20/25", "20/40", "20/80", "20/200", "20/400", "CLD à 3m", "CLD à 2m", "CLD à 1m", "VBLM", "PLMO", "PLBO"]
  snellenAVSCLeft: any
  snellenAVSCRight: any
  snellenAVACLeft: any
  snellenAVACRight: any

  oeilDroitAVLoin = false
  oeilGaucheAVLoin = false

  oeilDroitAVPres = false
  oeilGaucheAVPres = false
  // echeele Parinaud
  echelleParinaud = true
  echelleParinaudList = ["P1", "P2", "P3", "P4", "P5"]
  parinaudAVSCLeft: any
  parinaudAVSCRight: any
  parinaudAVACLeft: any
  parinaudAVACRight: any

  // endvaribales refraction

  //-------------------autres
  // todayExamenRef = false
  myExamen: any
  examRefraction: any
  examAnexe: any
  examLaf: any
  patient: any;
  selectedMotif: any
  timer: any
  minutes: any
  hour: any
  seconds: any
  idExamen: any;
  idPatient: any;
  // send data 
  objecttoSend = {
    rvLoinD: "",
    rvLoinG: "",
    rvPresD: "",
    rvPresG: "",
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    source: "",
    sansCycloSphereDroit: 0,
    sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0,
    sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0,
    avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0,
    avecCycloCylindreGauche: 0,
    addOD: 0,
    addOG: 0,
    nextAppointment: "",
    nextAppointmentDuration: "",
    nextMotif: ""

  };
  // anomalies by a doctor
  anomaliesByDoctor: any[] = []
  inscpectionoldlistAnomalies = ["Normal", "Attitude de malvoyance", "Buphtalmie", "Exphtalmie", "Énophtalmie", "Microphtalmie", "Leucocorie", "Prothèse oculaire", "Plaies", "Paralysie faciale", "Ptosis", "Strabisme"]
  newAnomalie = ""
  anomalieOeilRightListNew: any[] = []
  anomaliesByDoctorAnnexe: any[] = []
  annexeoldlistAnomalies = ["Normale", "Paralysie occulomotrice", "Ophtalmologie", "Insuffisance de convergence", "Réflet cornéen décentré", "Présence de strabisme", "Plaie", "Dermabrasion", "Ptosis", "Chalazion", "Orgelet", "Entropion", "Ectropion", "Diplopie", "Distichoasis", "Meeibomite", "DGM", "Telangiectasie", "Tumeur paplepbrale", "DCA", "DCC", "Sténose lacrymal", "Masse de la loge lacrymal", "Fistule", "Écoulement lacrymal", "Sonde de DCR en place"]
  newAnomalieAnnexe = ""
  // end anomalies by a doctor
  oldListConsultations: any[] = []
  p = 1
  p2 = 1
  p3 = 1
  displayHistorique = false;
  displayRefractionSubjectiveHist = false
  displayAcuiteVisuelleHist = false
  displayRefractionObjectiveHist = false
  // showRefSubjective = false
  // showAcuite = false
  // history variables
  selectedDate: string;
  selectedHistorique: any;
  selectedHistoriqueRefObj: any;
  isActive: string;
  selectedAcuiteHistoriqueLoin = true
  selectedAcuiteHistoriquePres = false
  isActiveSubMenu = "acuiteVisuelleHistLoin"
  // charts Options
  dates: string[] = [];

  acuiteLoinAVACgauche: any[] = []
  acuiteLoinAVACdroit: any[] = []
  acuiteLoinAVSCgauche: any[] = []
  acuiteLoinAVSCdroit: any[] = []
  acuitePresAVACgauche: any[] = []
  acuitePresAVACdroit: any[] = []
  acuitePresAVSCgauche: any[] = []
  acuitePresAVSCdroit: any[] = []

  // acuite de loin data
  lineChartData: any[] = [
    { data: this.acuiteLoinAVACdroit, label: 'AVAC oeil droit', fill: false },
    { data: this.acuiteLoinAVACgauche, label: 'AVAC oeil gauche', fill: false },
    { data: this.acuiteLoinAVSCdroit, label: 'AVSC oeil droit', fill: false },
    { data: this.acuiteLoinAVSCgauche, label: 'AVSC oeil gauche', fill: false, borderColor: 'black', pointBackgroundColor: "black", pointBorderColor: 'black' },
  ];
  // acuite de Pres data
  lineChartDataPres: any[] = [
    { data: this.acuitePresAVACdroit, label: 'AVAC oeil droit', fill: false },
    { data: this.acuitePresAVACgauche, label: 'AVAC oeil gauche', fill: false },
    { data: this.acuitePresAVSCdroit, label: 'AVSC oeil droit', fill: false },
    { data: this.acuitePresAVSCgauche, label: 'AVSC oeil gauche', fill: false, borderColor: 'black', pointBackgroundColor: "black", pointBorderColor: 'black' },
  ];


  lineChartLabels: string[] = this.dates;
  // acuite de loin options 
  lineChartOptions: any = {
    responsive: true,
    title: {
      display: true,
      text: "Courbe d'évolution de l'acuité visuelle de loin ",
      fontSize: 16,
      fontColor: "#21726B",
    },
    scales: {
      yAxes: [{
        ticks: {
          callback: function (value, index, values) {
            return ["20/10", "20/15", "20/20", "10/10", "9/10", "8/10", "20/25", "7/10", "6/10", "20/40", "5/10", "4/10", "3/10", "2/10", "1/10", "20/80", "20/200", "20/400", "CLD à 3m", "CLD à 2m", "CLD à 1m", "VBLM", "PLMO", "PLBO"][index];
          },
          stepSize: 1,
          min: 0,
          max: 23,
          // maxTicksLimit: 25,
          autoSkip: false,
          fixedStepSize: 1,
          fontColor: "black",
          fontSize: 12,
          fontStyle: 'normal',
          fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          padding: 10,
        }
      }]
    },

    tooltips: {
      mode: "index",
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          let echelle = ["PLBO", "PLMO", "VBLM", "CLD à 1m", "CLD à 2m", "CLD à 3m", "20/400", "20/200", "20/80", "1/10", "2/10", "3/10", "4/10", "5/10", "20/40", "6/10", "7/10", "20/25", "8/10", "9/10", "10/10", "20/20", "20/15", "20/10"]
          label += echelle[tooltipItem.yLabel];
          return label;
        }
      }
    }
  };
  // acuite de Pres options 
  lineChartOptionsPres: any = {
    responsive: true,
    title: {
      display: true,
      text: "Courbe d'évolution de l'acuité visuelle de Prés ",
      fontSize: 16,
      fontColor: "#21726B",
    },
    scales: {
      yAxes: [{
        ticks: {
          callback: function (value, index, values) {
            return ["P5", "P4", "P3", "P2", "P1"][index];
          },
          stepSize: 1,
          min: 0,
          max: 4,
          autoSkip: false,
          fixedStepSize: 1,
          fontColor: "black",
          fontSize: 12,
          fontStyle: 'normal',
          fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          padding: 10,
        }
      }]
    },

    tooltips: {
      mode: "index",
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          let echelle = ["P1", "P2", "P3", "P4", "P5"]
          label += echelle[tooltipItem.yLabel];
          return label;
        }
      }
    }
  };
  lineChartLegend = true;
  lineChartType = 'line';

  btnDataSubscription: Subscription;
  // charts Options
  paginatedList: any[] = [];
  itemsPerPage: number = 1; // Number of items per page
  paginationId: string = 'datePagination'; // ID for pagination
  constructor(private anomalieService: AnomalieService, private sharedDataService: SharedDataService, private patientService: PatientService, private responsiveService: ResponsiveService, private router: Router, private activatedRoute: ActivatedRoute, private examenService: ExamenService, private _sanitizer: DomSanitizer, public iziToast: Ng2IzitoastService, private medecinService: MedecinService) {
    let params: any = this.activatedRoute.snapshot.params;
    if (params.id && params.id != undefined && params.id != null && params.id != "undefined") {
      this.patientService.getPatientByIDNewFunction(params.id).subscribe(patient => {
        this.patient = patient;
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }

    // get data
    this.btnDataSubscription = this.sharedDataService.dataBtn$.subscribe((data) => {
      if (data && data.type && data.type == 'refraction') {
        this.updateExamen(this.examRefraction)
        return true
      }
    });
  }
  ngOnDestroy() {

    this.btnDataSubscription.unsubscribe();

  }
  ngOnInit() {

    const idExamen = this.activatedRoute.snapshot.paramMap.get('idExamen');
    const idPatient = this.activatedRoute.snapshot.paramMap.get('id');
    // get refract
    if (idExamen && idExamen != undefined && idExamen != null && idPatient && idPatient != undefined && idPatient != null && idPatient != "undefined") {
      this.examenService.getRefractionById(idExamen).subscribe((data: any) => {
        this.examRefraction = data
        if (this.examRefraction) {
          this.configureDataExamen(idPatient)
        } else {
          this.selectedMotif = localStorage.getItem('motif')
        }
      },
        err => {
          return false;
        });
    }

  }
  configureDataExamen(idPatient) {
    this.anomalieService.getAnomalieByDoctor("refraction").subscribe(data => {
      this.anomaliesByDoctor = data.results;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });
    this.examenService.getInspectionHistoriques(idPatient, "refraction").subscribe((data: any) => {
      this.oldListConsultations = data.reverse()
      for (const obj of this.oldListConsultations) {
        // dates
        this.dates.push(obj.start.substring(0, 10));
        // AVAC Loin Gauche
        const acuiteLoinAVACgauche = obj.refraction.acuitevisueldeloin.oielGauche.avac;
        const indexL = this.echelleList1.indexOf(acuiteLoinAVACgauche);
        this.acuiteLoinAVACgauche.push(indexL);
        // AVAC Loin Droit
        const acuiteLoinAVACDroit = obj.refraction.acuitevisueldeloin.oielDroit.avac;
        const indexL1 = this.echelleList1.indexOf(acuiteLoinAVACDroit);
        this.acuiteLoinAVACdroit.push(indexL1);
        // AVAC Loin Gauche
        const acuiteLoinAVSCgauche = obj.refraction.acuitevisueldeloin.oielGauche.avsc;
        const indexL2 = this.echelleList1.indexOf(acuiteLoinAVSCgauche);
        this.acuiteLoinAVSCgauche.push(indexL2);
        // AVAC Loin Droit
        const acuiteLoinAVSCDroit = obj.refraction.acuitevisueldeloin.oielDroit.avsc;
        const indexL3 = this.echelleList1.indexOf(acuiteLoinAVSCDroit);
        this.acuiteLoinAVSCdroit.push(indexL3);

        // AVAC Pres Gauche
        const acuitePresAVACgauche = obj.refraction.acuitevisueldepres.oielGauche.avac;
        const indexP = this.echelleParinaudList.indexOf(acuitePresAVACgauche);
        this.acuitePresAVACgauche.push(indexP);
        // AVAC Pres Droit
        const acuitePresAVACdroit = obj.refraction.acuitevisueldepres.oielDroit.avac;
        const indexP1 = this.echelleParinaudList.indexOf(acuitePresAVACdroit);
        this.acuitePresAVACdroit.push(indexP1);
        // AVAC Pres Gauche
        const acuitePresAVSCgauche = obj.refraction.acuitevisueldepres.oielGauche.avsc;
        const indexP2 = this.echelleParinaudList.indexOf(acuitePresAVSCgauche);
        this.acuitePresAVSCgauche.push(indexP2);
        // AVAC Pres Droit
        const acuitePresAVSCdroit = obj.refraction.acuitevisueldepres.oielDroit.avsc;
        const indexP3 = this.echelleParinaudList.indexOf(acuitePresAVSCdroit);
        this.acuitePresAVSCdroit.push(indexP3);
      }
      this.dates = this.dates
      this.acuiteLoinAVACdroit = this.acuiteLoinAVACdroit
      this.acuiteLoinAVACgauche = this.acuiteLoinAVACgauche
      this.acuiteLoinAVSCdroit = this.acuiteLoinAVSCdroit
      this.acuiteLoinAVSCgauche = this.acuiteLoinAVSCgauche
      this.acuitePresAVACdroit = this.acuitePresAVACdroit
      this.acuitePresAVACgauche = this.acuitePresAVACgauche
      this.acuitePresAVSCdroit = this.acuitePresAVSCdroit
      this.acuitePresAVSCgauche = this.acuitePresAVSCgauche

    },
      err => {
        return false;
      });
    // add acuité de pres
    if (this.examRefraction.refraction.acuitevisueldepres.oielGauche.add == undefined) {
      this.examRefraction.refraction.acuitevisueldepres.oielGauche.add = 0
    }
    if (this.examRefraction.refraction.acuitevisueldepres.oielDroit.add == undefined) {
      this.examRefraction.refraction.acuitevisueldepres.oielDroit.add = 0
    }
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.add !== undefined && this.examRefraction.refraction.acuitevisueldepres.oielGauche.add > 0 ? this.examRefraction.refraction.acuitevisueldepres.oielGauche.add = "+" + this.examRefraction.refraction.acuitevisueldepres.oielGauche.add : this.examRefraction.refraction.acuitevisueldepres.oielGauche.add = this.examRefraction.refraction.acuitevisueldepres.oielGauche.add
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.add !== undefined && this.examRefraction.refraction.acuitevisueldepres.oielDroit.add > 0 ? this.examRefraction.refraction.acuitevisueldepres.oielDroit.add = "+" + this.examRefraction.refraction.acuitevisueldepres.oielDroit.add : this.examRefraction.refraction.acuitevisueldepres.oielDroit.add = this.examRefraction.refraction.acuitevisueldepres.oielDroit.add
  }

  // update examen
  updateExamen(myExamen) {
    this.examenService.updateExamen(myExamen).subscribe((_patient: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
    return true;
  }


  showDataHistorique() {

    for (const obj of this.oldListConsultations) {
      // dates
      this.dates.push(obj.start.substring(0, 10));
      // AVAC Loin Gauche
      const acuiteLoinAVACgauche = obj.refraction.acuitevisueldeloin.oielGauche.avac;
      const indexL = this.echelleList1.indexOf(acuiteLoinAVACgauche);
      this.acuiteLoinAVACgauche.push(indexL);
      // AVAC Loin Droit
      const acuiteLoinAVACDroit = obj.refraction.acuitevisueldeloin.oielDroit.avac;
      const indexL1 = this.echelleList1.indexOf(acuiteLoinAVACDroit);
      this.acuiteLoinAVACdroit.push(indexL1);
      // AVAC Loin Gauche
      const acuiteLoinAVSCgauche = obj.refraction.acuitevisueldeloin.oielGauche.avsc;
      const indexL2 = this.echelleList1.indexOf(acuiteLoinAVSCgauche);
      this.acuiteLoinAVSCgauche.push(indexL2);
      // AVAC Loin Droit
      const acuiteLoinAVSCDroit = obj.refraction.acuitevisueldeloin.oielDroit.avsc;
      const indexL3 = this.echelleList1.indexOf(acuiteLoinAVSCDroit);
      this.acuiteLoinAVSCdroit.push(indexL3);

      // AVAC Pres Gauche
      const acuitePresAVACgauche = obj.refraction.acuitevisueldepres.oielGauche.avac;
      const indexP = this.echelleParinaudList.indexOf(acuitePresAVACgauche);
      this.acuitePresAVACgauche.push(indexP);
      // AVAC Pres Droit
      const acuitePresAVACdroit = obj.refraction.acuitevisueldepres.oielDroit.avac;
      const indexP1 = this.echelleParinaudList.indexOf(acuitePresAVACdroit);
      this.acuitePresAVACdroit.push(indexP1);
      // AVAC Pres Gauche
      const acuitePresAVSCgauche = obj.refraction.acuitevisueldepres.oielGauche.avsc;
      const indexP2 = this.echelleParinaudList.indexOf(acuitePresAVSCgauche);
      this.acuitePresAVSCgauche.push(indexP2);
      // AVAC Pres Droit
      const acuitePresAVSCdroit = obj.refraction.acuitevisueldepres.oielDroit.avsc;
      const indexP3 = this.echelleParinaudList.indexOf(acuitePresAVSCdroit);
      this.acuitePresAVSCdroit.push(indexP3);
    }
    this.dates = this.dates.reverse()
    this.acuiteLoinAVACdroit = this.acuiteLoinAVACdroit.reverse()
    this.acuiteLoinAVACgauche = this.acuiteLoinAVACgauche.reverse()
    this.acuiteLoinAVSCdroit = this.acuiteLoinAVSCdroit.reverse()
    this.acuiteLoinAVSCgauche = this.acuiteLoinAVSCgauche.reverse()
    this.acuitePresAVACdroit = this.acuitePresAVACdroit.reverse()
    this.acuitePresAVACgauche = this.acuitePresAVACgauche.reverse()
    this.acuitePresAVSCdroit = this.acuitePresAVSCdroit.reverse()
    this.acuitePresAVSCgauche = this.acuitePresAVSCgauche.reverse()
  }

  onDateSelect(date: string) {
    this.selectedHistorique = this.oldListConsultations.find(consultation => consultation.start === date);
  }
  onDateSelectRefOb(date: string) {
    this.selectedHistoriqueRefObj = this.oldListConsultations.find(consultation => consultation.start === date);
  }

  sendTodayExamToParent() {
    this.sendTodayExam.emit(this.todayExamenRef)
  }
  sendRefracDataToParent() {
    this.sendRefValues.emit(this.showRefSubjective)
  }
  sendAcuitDataToParent() {
    this.sendAcuitValues.emit(this.showAcuite);
  }
  //start code refraction
  showHideRefractionSubjective() {
    this.showRefSubjective = !this.showRefSubjective
    this.sendRefracDataToParent()
    this.sendTodayExamToParent()

  }
  showHideAcuite() {
    this.showAcuite = !this.showAcuite
    this.sendAcuitDataToParent()
    this.sendTodayExamToParent()
  }


  selectAvecCyclopAtropine() {
    this.examRefraction.refraction.avecCycloplegie.type = "atropine"
  }
  selectAvecCyclopCyclocol() {
    this.examRefraction.refraction.avecCycloplegie.type = "cyclocol"
  }
  selectAvecCyclopTropicamide() {
    this.examRefraction.refraction.avecCycloplegie.type = "tropicamide"
  }
  selectEchelleMonoyer() {
    this.examRefraction.refraction.acuitevisueldeloin.echelle = 'monoyer'
  }
  selectEchelleSnellen() {
    this.examRefraction.refraction.acuitevisueldeloin.echelle = 'snellen'
  }
  selectOeilDroitAVLoin() {
    this.examRefraction.refraction.acuitevisueldeloin.oeilDirecteur = "oielDroit"
  }
  selectOeilGaucheAVLoin() {
    this.examRefraction.refraction.acuitevisueldeloin.oeilDirecteur = "oielGauche"
  }
  selectOeilDroitAVPres() {
    this.examRefraction.refraction.acuitevisueldepres.oeilDirecteur = "oielDroit"
  }
  selectOeilGaucheAVPres() {
    this.examRefraction.refraction.acuitevisueldepres.oeilDirecteur = "oielGauche"
  }

  checkSign(event) {
    if (event.target.value <= 0) {
      event.target.value = 0
    }
  }
  selectRVOeilDroitLoin(value) {
    this.examRefraction.refraction.acuitevisueldeloin.oielDroit.rv = value

    this.objecttoSend.rvLoinD = value
    this.objecttoSend.source = "rvLoinD"
    this.sharedDataService.updateData(this.objecttoSend);
  }
  selectRVOeilGaucheLoin(value) {
    this.examRefraction.refraction.acuitevisueldeloin.oielGauche.rv = value

    this.objecttoSend.rvLoinG = value
    this.objecttoSend.source = "rvLoinG"
    this.sharedDataService.updateData(this.objecttoSend);
  }
  selectRVOeilDroitPres(value) {
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.rv = value
    this.objecttoSend.source = "rvPresD"
    this.objecttoSend.rvPresD = value
    this.sharedDataService.updateData(this.objecttoSend);
  }
  selectRVOeilGauchePres(value) {
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.rv = value
    this.objecttoSend.source = "rvPresG"
    this.objecttoSend.rvPresG = value
    this.sharedDataService.updateData(this.objecttoSend);
  }
  // end code reffaction

  // update SansCyclo 
  // !----------------Sans cyclo
  //!----- Sphére  
  changeSansCycloSphereDroit(value) {
    this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere > 0) {
        this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere = "+" + this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere
      } else {
        this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere = this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere
      }
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere = value
    }
    this.objecttoSend.sansCycloSphereDroit = this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere
    this.objecttoSend.source = "sansCycloSphereDroit";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  incrementscODSph() {
    this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere = parseFloat(this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere);
    if (isNaN(this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere) || this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere === undefined) {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere = 0;
    } else if (this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere >= 0) {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere += 0.25;
      this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere = "+" + this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere += 0.25;
    }

    this.objecttoSend.sansCycloSphereDroit = this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere;
    this.objecttoSend.source = "sansCycloSphereDroit";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  decrementscODSph() {
    this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere = parseFloat(this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere);
    if (isNaN(this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere) || this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere === undefined) {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere = 0;
    } else if (this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere <= 0) {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere -= 0.25;
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere -= 0.25;
      if (this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere > 0) {
        this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere = "+" + this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere
      }
    }
    this.objecttoSend.sansCycloSphereDroit = this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere;
    this.objecttoSend.source = "sansCycloSphereDroit";
    this.sharedDataService.updateData(this.objecttoSend);
  }

  changeSansCycloSphereGauche(value) {
    this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere > 0) {
        this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere = "+" + this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere
      } else {
        this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere = this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere
      }
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere = value;
    }
    this.objecttoSend.sansCycloSphereGauche = this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere
    this.objecttoSend.source = "sansCycloSphereGauche";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  incrementscOGSph() {
    this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere = parseFloat(this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere);
    if (isNaN(this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere) || this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere === undefined) {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere = 0;
    } else if (this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere >= 0) {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere += 0.25;
      this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere = "+" + this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere += 0.25;
    }
    this.objecttoSend.sansCycloSphereGauche = this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere
    this.objecttoSend.source = "sansCycloSphereGauche";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  decrementscOGSph() {
    this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere = parseFloat(this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere);
    if (isNaN(this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere) || this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere === undefined) {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere = 0;
    } else if (this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere <= 0) {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere -= 0.25;
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere -= 0.25;
      if (this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere > 0) {
        this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere = "+" + this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere
      }
    }
    this.objecttoSend.sansCycloSphereGauche = this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere
    this.objecttoSend.source = "sansCycloSphereGauche";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  //!----- end of Sphére
  //!-----  Cylindre  
  changeSansCycloCylindreDroit(value) {
    this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre > 0) {
        this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre = "+" + this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre
      } else {
        this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre = this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre
      }
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre = value;
    }
    this.objecttoSend.sansCycloCylindreDroit = this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre
    this.objecttoSend.source = "sansCycloCylindreDroit";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  incrementscODCy() {
    this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre = parseFloat(this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre);
    if (isNaN(this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre) || this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre === undefined) {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre = 0;
    } else if (this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre >= 0) {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre += 0.25;
      this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre = "+" + this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre += 0.25;
    }

    this.objecttoSend.sansCycloCylindreDroit = this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre
    this.objecttoSend.source = "sansCycloCylindreDroit";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  decrementscODCy() {
    this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre = parseFloat(this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre);
    if (isNaN(this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre) || this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre === undefined) {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre = 0;
    } else if (this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre <= 0) {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre -= 0.25;
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre -= 0.25;
      if (this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre > 0) {
        this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre = "+" + this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre
      }
    }
    this.objecttoSend.sansCycloCylindreDroit = this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre
    this.objecttoSend.source = "sansCycloCylindreDroit";
    this.sharedDataService.updateData(this.objecttoSend);
  }

  changeSansCycloCylindreGauche(value) {
    this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre > 0) {
        this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre = "+" + this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre
      } else {
        this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre = this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre
      }
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre = value;
    }

    this.objecttoSend.sansCycloCylindreGauche = this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre
    this.objecttoSend.source = "sansCycloCylindreGauche";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  incrementscOGCy() {
    this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre = parseFloat(this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre);
    if (isNaN(this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre) || this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre === undefined) {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre = 0;
    } else if (this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre >= 0) {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre += 0.25;
      this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre = "+" + this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre += 0.25;
    }

    this.objecttoSend.sansCycloCylindreGauche = this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre
    this.objecttoSend.source = "sansCycloCylindreGauche";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  decrementscOGCy() {
    this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre = parseFloat(this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre);
    if (isNaN(this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre) || this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre === undefined) {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre = 0;
    } else if (this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre <= 0) {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre -= 0.25;
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre -= 0.25;
      if (this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre > 0) {
        this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre = "+" + this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre;
      }
    }
    this.objecttoSend.sansCycloCylindreGauche = this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre
    this.objecttoSend.source = "sansCycloCylindreGauche";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  //!----- end of Cylindre  
  //!-----  Axe  
  changeScODAxe(value) {
    this.examRefraction.refraction.sansCycloplegie.oielDroit.axe = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.sansCycloplegie.oielDroit.axe < 0) {
        this.examRefraction.refraction.sansCycloplegie.oielDroit.axe = 0;
      } else {
        this.examRefraction.refraction.sansCycloplegie.oielDroit.axe
      }
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.axe = value;
    }
  }

  incrementScODAxe() {
    this.examRefraction.refraction.sansCycloplegie.oielDroit.axe = parseFloat(this.examRefraction.refraction.sansCycloplegie.oielDroit.axe);
    if (isNaN(this.examRefraction.refraction.sansCycloplegie.oielDroit.axe) || this.examRefraction.refraction.sansCycloplegie.oielDroit.axe === undefined) {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.axe = 0;
    } else if (this.examRefraction.refraction.sansCycloplegie.oielDroit.axe >= 0) {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.axe += 5;
      this.examRefraction.refraction.sansCycloplegie.oielDroit.axe
    }
  }

  decrementScODAxe() {
    this.examRefraction.refraction.sansCycloplegie.oielDroit.axe = parseFloat(this.examRefraction.refraction.sansCycloplegie.oielDroit.axe);
    if (isNaN(this.examRefraction.refraction.sansCycloplegie.oielDroit.axe) || this.examRefraction.refraction.sansCycloplegie.oielDroit.axe === undefined) {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.axe = 0;
    } else if (this.examRefraction.refraction.sansCycloplegie.oielDroit.axe <= 1) {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.axe = 0;
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.axe -= 5;
      if (this.examRefraction.refraction.sansCycloplegie.oielDroit.axe < 0) {
        this.examRefraction.refraction.sansCycloplegie.oielDroit.axe = 0;
      }
    }
  }
  changeScOGAxe(value) {
    this.examRefraction.refraction.sansCycloplegie.oielGauche.axe = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.sansCycloplegie.oielGauche.axe < 0) {
        this.examRefraction.refraction.sansCycloplegie.oielGauche.axe = 0;
      } else {
        this.examRefraction.refraction.sansCycloplegie.oielGauche.axe
      }
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.axe = value;
    }
  }

  incrementScOGAxe() {
    this.examRefraction.refraction.sansCycloplegie.oielGauche.axe = parseFloat(this.examRefraction.refraction.sansCycloplegie.oielGauche.axe);
    if (isNaN(this.examRefraction.refraction.sansCycloplegie.oielGauche.axe) || this.examRefraction.refraction.sansCycloplegie.oielGauche.axe === undefined) {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.axe = 0;
    } else if (this.examRefraction.refraction.sansCycloplegie.oielGauche.axe >= 0) {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.axe += 5;
    }
  }

  decrementScOGAxe() {
    this.examRefraction.refraction.sansCycloplegie.oielGauche.axe = parseFloat(this.examRefraction.refraction.sansCycloplegie.oielGauche.axe);
    if (isNaN(this.examRefraction.refraction.sansCycloplegie.oielGauche.axe) || this.examRefraction.refraction.sansCycloplegie.oielGauche.axe === undefined) {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.axe = 0;
    } else if (this.examRefraction.refraction.sansCycloplegie.oielGauche.axe <= 1) {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.axe = 0;
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.axe -= 5;
      if (this.examRefraction.refraction.sansCycloplegie.oielGauche.axe < 0) {
        this.examRefraction.refraction.sansCycloplegie.oielGauche.axe = 0
      }
    }
  }
  //!----- end of  Axe  
  //!-----  kératométrie  
  changeScODKera(value) {
    this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie < 0) {
        this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie = 0;
      } else {
        this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie = "+" + this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie
      }
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie = value;
    }
  }

  incrementScODKera() {
    this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie = parseFloat(this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie);
    if (isNaN(this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie) || this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie === undefined) {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie = 0;
    } else if (this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie >= 0) {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie += 1;
      this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie = "+" + this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie
    }
  }

  decrementScODKera() {
    this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie = parseFloat(this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie);
    if (isNaN(this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie) || this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie === undefined) {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie = 0;
    } else if (this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie <= 1) {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie = 0;
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie -= 1;
      if (this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie > 0) {
        this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie = "+" + this.examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie;
      }
    }
  }
  changeScOGKera(value) {
    this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie < 0) {
        this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie = 0;
      } else {
        this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie = "+" + this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie
      }
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie = value;
    }
  }

  incrementScOGKera() {
    this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie = parseFloat(this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie);
    if (isNaN(this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie) || this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie === undefined) {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie = 0;
    } else if (this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie >= 0) {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie += 1;
      this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie = "+" + this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie
    }
  }

  decrementScOGKera() {
    this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie = parseFloat(this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie);
    if (isNaN(this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie) || this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie === undefined) {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie = 0;
    } else if (this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie <= 1) {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie = 0;
    } else {
      this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie -= 1;
      if (this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie > 0) {
        this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie = "+" + this.examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie;
      }
    }
  }
  //!-----  end of kératométrie  
  // !--------------- end of Sans cyclo


  // ?----------------Avec cyclo
  //?----- Sphére  
  changeAvecCycloSphereDroit(value) {
    this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere > 0) {
        this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere = "+" + this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere
      } else {
        this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere = this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere
      }
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere = value;
    }
    this.objecttoSend.avecCycloSphereDroit = this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere;
    this.objecttoSend.source = "avecCycloSphereDroit";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  incrementAcODSph() {
    this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere = parseFloat(this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere);
    if (isNaN(this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere) || this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere === undefined) {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere = 0;
    } else if (this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere >= 0) {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere += 0.25;
      this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere = "+" + this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere += 0.25;
    }

    this.objecttoSend.avecCycloSphereDroit = this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere;
    this.objecttoSend.source = "avecCycloSphereDroit";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  decrementAcODSph() {
    this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere = parseFloat(this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere);
    if (isNaN(this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere) || this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere === undefined) {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere = 0;
    } else if (this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere <= 0) {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere -= 0.25;
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere -= 0.25;
      if (this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere > 0) {
        this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere = "+" + this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere
      }
    }
    this.objecttoSend.avecCycloSphereDroit = this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere;
    this.objecttoSend.source = "avecCycloSphereDroit";
    this.sharedDataService.updateData(this.objecttoSend);
  }

  changeAvecCycloSphereGauche(value) {
    this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere > 0) {
        this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere = "+" + this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere
      } else {
        this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere = this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere
      }
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere = value;
    }
    this.objecttoSend.avecCycloSphereGauche = this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere
    this.objecttoSend.source = "avecCycloSphereGauche";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  incrementAcOGSph() {
    this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere = parseFloat(this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere);
    if (isNaN(this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere) || this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere === undefined) {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere = 0;
    } else if (this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere >= 0) {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere += 0.25;
      this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere = "+" + this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere += 0.25;
    }
    this.objecttoSend.avecCycloSphereGauche = this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere
    this.objecttoSend.source = "avecCycloSphereGauche";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  decrementAcOGSph() {
    this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere = parseFloat(this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere);
    if (isNaN(this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere) || this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere === undefined) {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere = 0;
    } else if (this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere <= 0) {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere -= 0.25;
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere -= 0.25;
      if (this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere > 0) {
        this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere = "+" + this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere
      }
    }
    this.objecttoSend.avecCycloSphereGauche = this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere
    this.objecttoSend.source = "avecCycloSphereGauche";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  //?----- end of Sphére
  //?-----  Cylindre  
  changeAvecCycloCylindreDroit(value) {
    this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre > 0) {
        this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre = "+" + this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre
      } else {
        this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre = this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre
      }
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre = value;
    }
    this.objecttoSend.avecCycloCylindreDroit = this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre
    this.objecttoSend.source = "avecCycloCylindreDroit";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  incrementAcODCy() {
    this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre = parseFloat(this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre);
    if (isNaN(this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre) || this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre === undefined) {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre = 0;
    } else if (this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre >= 0) {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre += 0.25;
      this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre = "+" + this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre += 0.25;
    }

    this.objecttoSend.avecCycloCylindreDroit = this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre
    this.objecttoSend.source = "avecCycloCylindreDroit";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  decrementAcODCy() {
    this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre = parseFloat(this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre);
    if (isNaN(this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre) || this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre === undefined) {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre = 0;
    } else if (this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre <= 0) {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre -= 0.25;
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre -= 0.25;
      if (this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre > 0) {
        this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre = "+" + this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre
      }
    }
    this.objecttoSend.avecCycloCylindreDroit = this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre
    this.objecttoSend.source = "avecCycloCylindreDroit";
    this.sharedDataService.updateData(this.objecttoSend);
  }

  changeAvecCycloCylindreGauche(value) {
    this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre > 0) {
        this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre = "+" + this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre
      } else {
        this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre = this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre
      }
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre = value;
    }
    this.objecttoSend.avecCycloCylindreGauche = this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre
    this.objecttoSend.source = "avecCycloCylindreGauche";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  incrementAcOGCy() {
    this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre = parseFloat(this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre);
    if (isNaN(this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre) || this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre === undefined) {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre = 0;
    } else if (this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre >= 0) {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre += 0.25;
      this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre = "+" + this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre += 0.25;
    }
    this.objecttoSend.avecCycloCylindreGauche = this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre
    this.objecttoSend.source = "avecCycloCylindreGauche";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  decrementAcOGCy() {
    this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre = parseFloat(this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre);
    if (isNaN(this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre) || this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre === undefined) {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre = 0;
    } else if (this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre <= 0) {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre -= 0.25;
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre -= 0.25;
      if (this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre > 0) {
        this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre = "+" + this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre;
      }
    }
    this.objecttoSend.avecCycloCylindreGauche = this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre
    this.objecttoSend.source = "avecCycloCylindreGauche";
    this.sharedDataService.updateData(this.objecttoSend);
  }
  //?----- end of Cylindre  
  //?-----  Axe  
  changeAcODAxe(value) {
    this.examRefraction.refraction.avecCycloplegie.oielDroit.axe = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.avecCycloplegie.oielDroit.axe < 0) {
        this.examRefraction.refraction.avecCycloplegie.oielDroit.axe = 0;
      } else {
        this.examRefraction.refraction.avecCycloplegie.oielDroit.axe
      }
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.axe = value;
    }
  }

  incrementAcODAxe() {
    this.examRefraction.refraction.avecCycloplegie.oielDroit.axe = parseFloat(this.examRefraction.refraction.avecCycloplegie.oielDroit.axe);
    if (isNaN(this.examRefraction.refraction.avecCycloplegie.oielDroit.axe) || this.examRefraction.refraction.avecCycloplegie.oielDroit.axe === undefined) {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.axe = 0;
    } else if (this.examRefraction.refraction.avecCycloplegie.oielDroit.axe >= 0) {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.axe += 5;
    }
  }

  decrementAcODAxe() {
    this.examRefraction.refraction.avecCycloplegie.oielDroit.axe = parseFloat(this.examRefraction.refraction.avecCycloplegie.oielDroit.axe);
    if (isNaN(this.examRefraction.refraction.avecCycloplegie.oielDroit.axe) || this.examRefraction.refraction.avecCycloplegie.oielDroit.axe === undefined) {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.axe = 0;
    } else if (this.examRefraction.refraction.avecCycloplegie.oielDroit.axe <= 1) {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.axe = 0;
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.axe -= 5;
      if (this.examRefraction.refraction.avecCycloplegie.oielDroit.axe < 0) {
        this.examRefraction.refraction.avecCycloplegie.oielDroit.axe = 0;
      }
    }
  }
  changeAcOGAxe(value) {
    this.examRefraction.refraction.avecCycloplegie.oielGauche.axe = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.avecCycloplegie.oielGauche.axe < 0) {
        this.examRefraction.refraction.avecCycloplegie.oielGauche.axe = 0;
      } else {
        this.examRefraction.refraction.avecCycloplegie.oielGauche.axe
      }
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.axe = value;
    }
  }

  incrementAcOGAxe() {
    this.examRefraction.refraction.avecCycloplegie.oielGauche.axe = parseFloat(this.examRefraction.refraction.avecCycloplegie.oielGauche.axe);
    if (isNaN(this.examRefraction.refraction.avecCycloplegie.oielGauche.axe) || this.examRefraction.refraction.avecCycloplegie.oielGauche.axe === undefined) {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.axe = 0;
    } else if (this.examRefraction.refraction.avecCycloplegie.oielGauche.axe >= 0) {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.axe += 5;
    }
  }

  decrementAcOGAxe() {
    this.examRefraction.refraction.avecCycloplegie.oielGauche.axe = parseFloat(this.examRefraction.refraction.avecCycloplegie.oielGauche.axe);
    if (isNaN(this.examRefraction.refraction.avecCycloplegie.oielGauche.axe) || this.examRefraction.refraction.avecCycloplegie.oielGauche.axe === undefined) {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.axe = 0;
    } else if (this.examRefraction.refraction.avecCycloplegie.oielGauche.axe <= 1) {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.axe = 0;
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.axe -= 5;
      if (this.examRefraction.refraction.avecCycloplegie.oielGauche.axe < 0) {
        this.examRefraction.refraction.avecCycloplegie.oielGauche.axe = 0
      }
    }
  }
  //?----- end of  Axe  
  //?-----  kératométrie  
  changeAcODKera(value) {
    this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie < 0) {
        this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie = 0;
      } else {
        this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie = "+" + this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie
      }
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie = value;
    }
  }

  incrementAcODKera() {
    this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie = parseFloat(this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie);
    if (isNaN(this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie) || this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie === undefined) {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie = 0;
    } else if (this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie >= 0) {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie += 1;
      this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie = "+" + this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie
    }
  }

  decrementAcODKera() {
    this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie = parseFloat(this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie);
    if (isNaN(this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie) || this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie === undefined) {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie = 0;
    } else if (this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie <= 1) {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie = 0;
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie -= 1;
      if (this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie > 0) {
        this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie = "+" + this.examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie;
      }
    }
  }
  changeAcOGKera(value) {
    this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie < 0) {
        this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie = 0;
      } else {
        this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie = "+" + this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie
      }
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie = value;
    }
  }

  incrementAcOGKera() {
    this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie = parseFloat(this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie);
    if (isNaN(this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie) || this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie === undefined) {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie = 0;
    } else if (this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie >= 0) {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie += 1;
      this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie = "+" + this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie
    }
  }

  decrementAcOGKera() {
    this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie = parseFloat(this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie);
    if (isNaN(this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie) || this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie === undefined) {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie = 0;
    } else if (this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie <= 1) {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie = 0;
    } else {
      this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie -= 1;
      if (this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie > 0) {
        this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie = "+" + this.examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie;
      }
    }
  }
  //?-----  end of kératométrie  
  // ?--------------- end of Avec cyclo
  isStringAndNotNaN(value: any): boolean {
    return typeof value === 'string' && value.toLowerCase() !== 'nan';
  }
  // !---------------------Add
  onchangeAdddroit(value) {
    // Convert the input value to a number or set it to 0 if it's invalid
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.add = parseFloat(value);
    let addValue = this.examRefraction.refraction.acuitevisueldepres.oielDroit.add;

    // Check if addValue is NaN or negative, and set to 0 if so
    if (isNaN(addValue) || addValue < 0) {
      addValue = this.examRefraction.refraction.acuitevisueldepres.oielDroit.add = 0;
    }

    const attributes = ["sphere"];
    const deLoinOeilDroit = this.examRefraction.refraction.acuitevisueldeloin.oielDroit;
    const dePresOeilDroit = this.examRefraction.refraction.acuitevisueldepres.oielDroit;

    attributes.forEach(attr => {
      let loinValue = parseFloat(deLoinOeilDroit[attr]);
      let presValue = parseFloat(dePresOeilDroit[attr]);

      if (!isNaN(loinValue) && !isNaN(presValue)) {
        dePresOeilDroit[attr] = loinValue + addValue;
      } else if (!isNaN(presValue) && !this.isStringAndNotNaN(loinValue)) {
        dePresOeilDroit[attr] = addValue;
      } else if (isNaN(loinValue) && isNaN(presValue)) {
        dePresOeilDroit[attr];
      }
      // Add "+" sign to positive values
      if (dePresOeilDroit[attr] === 0) {
        dePresOeilDroit[attr] = "Plan";
      } else if (dePresOeilDroit[attr] > 0) {
        dePresOeilDroit[attr] = "+" + dePresOeilDroit[attr];
      }


    });

    // Add "+" sign to the add value if positive
    if (addValue > 0) {
      dePresOeilDroit.add = "+" + addValue;
    }

    // Updating shared data service
    this.objecttoSend.source = "addOD";
    this.objecttoSend.addOD = this.examRefraction.refraction.acuitevisueldepres.oielDroit.add;
    this.sharedDataService.updateData(this.objecttoSend);
  }

  increment9() {
    if (this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere === "Plan") {
      this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere = 0
    }
    // Convert the value to a number
    let addPres = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.add);
    let spherePres = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere)
    let spehreLoin = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielDroit.sphere)



    // Ensure `add` is not negative
    if (addPres < 0) {
      addPres = 0;
    } else {
      // Increment `add` by 0.25 if it's non-negative
      addPres += 0.25;
    }

    // Process sphere value
    if (!isNaN(spherePres) && !isNaN(spehreLoin)) {
      spherePres = spehreLoin + addPres;
    } else if (!isNaN(spherePres) && !this.isStringAndNotNaN(spehreLoin)) {
      spherePres = addPres;
    } else if (!this.isStringAndNotNaN(spherePres) && !this.isStringAndNotNaN(spehreLoin)) {
      spherePres = this.examRefraction.refraction.acuitevisueldepres.oielDroit.spher;
    }

    // Ensure positive numbers are formatted with a "+" prefix
    if (spherePres === 0) {
      this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere = "Plan"
    } else if (spherePres > 0) {
      this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere = "+" + spherePres;
    } else {
      this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere = spherePres
    }


    if (addPres > 0) {
      this.examRefraction.refraction.acuitevisueldepres.oielDroit.add = "+" + addPres;
    }

    // Update the shared data
    this.objecttoSend.source = "addOD";
    this.objecttoSend.addOD = this.examRefraction.refraction.acuitevisueldepres.oielDroit.add;
    this.sharedDataService.updateData(this.objecttoSend);
  }

  decrement9() {
    // Ensure the 'add' value is a float
    let presAdd = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.add);
    let spherePres = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere)
    let spehreLoin = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielDroit.sphere)

    // Decrease 'add' by 0.25 if it's 0.25 or more
    if (presAdd >= 0.25) {
      presAdd -= 0.25;

      if (this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere === "Plan") {
        this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere = 0
      }
      // Process sphere value
      if (!isNaN(spherePres) && !isNaN(spehreLoin)) {
        spherePres = spehreLoin + presAdd;
        this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere = spherePres
      } else if (!isNaN(spherePres) && this.isStringAndNotNaN(this.examRefraction.refraction.acuitevisueldeloin.oielDroit.sphere)) {
        spherePres = presAdd;
        this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere = spherePres
      } else if (this.isStringAndNotNaN(spherePres) && this.isStringAndNotNaN(spehreLoin)) {
        this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere;
      }

      // Add '+' prefix for positive values
      if (spherePres === 0) {
        this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere = "Plan"
      } else if (spherePres > 0) {
        this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere = "+" + spherePres;
      }

      if (presAdd > 0) {
        this.examRefraction.refraction.acuitevisueldepres.oielDroit.add = "+" + presAdd;
      }
      // Ensure 'add' is not less than 0
      if (presAdd <= 0) {
        this.examRefraction.refraction.acuitevisueldepres.oielDroit.add = 0;
      }
      // Update shared data
      this.objecttoSend.source = "addOD";
      this.objecttoSend.addOD = this.examRefraction.refraction.acuitevisueldepres.oielDroit.add;
      this.sharedDataService.updateData(this.objecttoSend);
    }
  }

  onchangeAddGauche(value) {
    // Convert the input value to a number or set it to 0 if it's invalid
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.add = parseFloat(value);
    let addValue = this.examRefraction.refraction.acuitevisueldepres.oielGauche.add;

    // Check if addValue is NaN or negative, and set to 0 if so
    if (isNaN(addValue) || addValue < 0) {
      addValue = this.examRefraction.refraction.acuitevisueldepres.oielGauche.add = 0;
    }

    const attributes = ["sphere"];
    const deLoinOeilDroit = this.examRefraction.refraction.acuitevisueldeloin.oielGauche;
    const dePresOeilDroit = this.examRefraction.refraction.acuitevisueldepres.oielGauche;

    attributes.forEach(attr => {
      let loinValue = parseFloat(deLoinOeilDroit[attr]);
      let presValue = parseFloat(dePresOeilDroit[attr]);

      if (!isNaN(loinValue) && !isNaN(presValue)) {
        dePresOeilDroit[attr] = loinValue + addValue;
      } else if (!isNaN(presValue) && !this.isStringAndNotNaN(loinValue)) {
        dePresOeilDroit[attr] = addValue;
      } else if (isNaN(loinValue) && isNaN(presValue)) {
        dePresOeilDroit[attr];
      }
      // Add "+" sign to positive values
      if (dePresOeilDroit[attr] === 0) {
        dePresOeilDroit[attr] = "Plan";
      } else if (dePresOeilDroit[attr] > 0) {
        dePresOeilDroit[attr] = "+" + dePresOeilDroit[attr];
      }
    });

    // Add "+" sign to the add value if positive
    if (addValue > 0) {
      dePresOeilDroit.add = "+" + addValue;
    }
    // Updating shared data service
    this.objecttoSend.source = "addOG";
    this.objecttoSend.addOG = this.examRefraction.refraction.acuitevisueldepres.oielGauche.add;
    this.sharedDataService.updateData(this.objecttoSend);
  }

  increment10() {
    if (this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere === "Plan") {
      this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere = 0
    }
    // Convert the value to a number
    let addPres = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.add);
    let spherePres = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere)
    let spehreLoin = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielGauche.sphere)



    // Ensure `add` is not negative
    if (addPres < 0) {
      addPres = 0;
    } else {
      // Increment `add` by 0.25 if it's non-negative
      addPres += 0.25;
    }

    // Process sphere value
    if (!isNaN(spherePres) && !isNaN(spehreLoin)) {
      spherePres = spehreLoin + addPres;
    } else if (!isNaN(spherePres) && !this.isStringAndNotNaN(spehreLoin)) {
      spherePres = addPres;
    } else if (!this.isStringAndNotNaN(spherePres) && !this.isStringAndNotNaN(spehreLoin)) {
      spherePres = this.examRefraction.refraction.acuitevisueldepres.oielGauche.spher;
    }

    // Ensure positive numbers are formatted with a "+" prefix
    if (spherePres === 0) {
      this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere = "Plan"
    } else if (spherePres > 0) {
      this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere = "+" + spherePres;
    } else {
      this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere = spherePres
    }


    if (addPres > 0) {
      this.examRefraction.refraction.acuitevisueldepres.oielGauche.add = "+" + addPres;
    }

    // Update the shared data
    this.objecttoSend.source = "addOG";
    this.objecttoSend.addOG = this.examRefraction.refraction.acuitevisueldepres.oielGauche.add;
    this.sharedDataService.updateData(this.objecttoSend);
  }

  decrement10() {
    // Ensure the 'add' value is a float
    let presAdd = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.add);
    let spherePres = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere)
    let spehreLoin = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielGauche.sphere)

    // Decrease 'add' by 0.25 if it's 0.25 or more
    if (presAdd >= 0.25) {
      presAdd -= 0.25;

      if (this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere === "Plan") {
        this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere = 0
      }
      // Process sphere value
      if (!isNaN(spherePres) && !isNaN(spehreLoin)) {
        spherePres = spehreLoin + presAdd;
        this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere = spherePres
      } else if (!isNaN(spherePres) && this.isStringAndNotNaN(this.examRefraction.refraction.acuitevisueldeloin.oielGauche.sphere)) {
        spherePres = presAdd;
        this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere = spherePres
      } else if (this.isStringAndNotNaN(spherePres) && this.isStringAndNotNaN(spehreLoin)) {
        this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere;
      }

      // Add '+' prefix for positive values
      if (spherePres === 0) {
        this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere = "Plan"
      } else if (spherePres > 0) {
        this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere = "+" + spherePres;
      }

      if (presAdd > 0) {
        this.examRefraction.refraction.acuitevisueldepres.oielGauche.add = "+" + presAdd;
      }
      // Ensure 'add' is not less than 0
      if (presAdd <= 0) {
        this.examRefraction.refraction.acuitevisueldepres.oielGauche.add = 0;
      }
      // Update shared data
      this.objecttoSend.source = "addOG";
      this.objecttoSend.addOG = this.examRefraction.refraction.acuitevisueldepres.oielGauche.add;
      this.sharedDataService.updateData(this.objecttoSend);
    }
  }

  // todo--- Acuite visuel de loin
  //todo----- Sphére  
  changeLoinODSph(value) {
    const acuiteVisuelLoin = this.examRefraction.refraction.acuitevisueldeloin.oielDroit;
    const acuiteVisuelPres = this.examRefraction.refraction.acuitevisueldepres.oielDroit;

    // Parse value once
    let sphereLoin = parseFloat(value);

    if (sphereLoin === 0) {
      acuiteVisuelLoin.sphere = "Plan";
      acuiteVisuelPres.sphere = parseFloat(acuiteVisuelPres.add);

      if (acuiteVisuelPres.sphere > 0) {
        acuiteVisuelPres.sphere = `+${acuiteVisuelPres.sphere}`;
      } else if (acuiteVisuelPres.sphere === 0) {
        acuiteVisuelPres.sphere = "Plan";
      }
      return;
    }

    // Handle non-numeric or NaN cases
    if (isNaN(sphereLoin)) {
      acuiteVisuelLoin.sphere = value;
      return;
    }

    // Handle numeric cases
    if (sphereLoin > 0) {
      acuiteVisuelLoin.sphere = `+${sphereLoin}`;
    } else {
      acuiteVisuelLoin.sphere = sphereLoin;
    }

    acuiteVisuelPres.sphere = sphereLoin + parseFloat(acuiteVisuelPres.add);
    if (acuiteVisuelPres.sphere === 0) {
      acuiteVisuelPres.sphere = 'Plan';
    } else if (acuiteVisuelPres.sphere > 0) {
      acuiteVisuelPres.sphere = `+${acuiteVisuelPres.sphere}`;
    }
  }

  incrementLoinODSph() {
    // Parse values as floats, initializing as 0 if parsing fails
    let acuiteVisuelLoinSphere = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielDroit.sphere) || 0;
    const add = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.add) || 0;

    // Check if the current sphere value is "Plan"
    if (this.examRefraction.refraction.acuitevisueldeloin.oielDroit.sphere === "Plan") {
      acuiteVisuelLoinSphere = 0;
    }


    if (isNaN(acuiteVisuelLoinSphere) || acuiteVisuelLoinSphere === undefined) {
      acuiteVisuelLoinSphere = 0;
    }

    acuiteVisuelLoinSphere += 0.25;
    let updatedSpherePres = acuiteVisuelLoinSphere + add;


    // Format the values
    let formattedSphereLoin;
    let formattedSpherePres;


    // Format loin sphere value
    if (acuiteVisuelLoinSphere === 0) {
      formattedSphereLoin = "Plan";
    } else {
      formattedSphereLoin = acuiteVisuelLoinSphere > 0
        ? `+${acuiteVisuelLoinSphere}`
        : `${acuiteVisuelLoinSphere}`;
    }

    // Format pres sphere value
    formattedSpherePres = updatedSpherePres === 0
      ? "Plan"
      : updatedSpherePres > 0
        ? `+${updatedSpherePres}`
        : `${updatedSpherePres}`;


    // Update the original object properties
    this.examRefraction.refraction.acuitevisueldeloin.oielDroit.sphere = formattedSphereLoin;
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere = formattedSpherePres;
  }

  decrementLoinODSph() {
    // Convert current sphere to a float, with fallback to 0 if conversion fails
    let loinSphere = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielDroit.sphere) || 0;
    let presSphere = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere) || 0;
    const add = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.add) || 0;

    // Check if the current sphere value is "Plan" and convert it back to 0 for operations
    if (this.examRefraction.refraction.acuitevisueldeloin.oielDroit.sphere === "Plan") {
      loinSphere = 0;
    }

    // Decrement the sphere value
    loinSphere -= 0.25;

    // Update loinSphere value after decrement
    if (isNaN(loinSphere)) {
      loinSphere = 0;
    }

    // Calculate presSphere based on the updated loinSphere
    presSphere = loinSphere + add;


    // Set 'sphere' to "Plan" if it becomes exactly 0 after decrementing
    let formattedLoinSphere = loinSphere === 0 ? "Plan" : loinSphere > 0 ? `+${loinSphere}` : `${loinSphere}`;
    let formattedPresSphere = presSphere === 0 ? "Plan" : presSphere > 0 ? `+${presSphere}` : `${presSphere}`;

    // Update the original object properties
    this.examRefraction.refraction.acuitevisueldeloin.oielDroit.sphere = formattedLoinSphere;
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere = formattedPresSphere;

  }


  changeLoinLoinOGSph(value) {
    const acuiteVisuelLoin = this.examRefraction.refraction.acuitevisueldeloin.oielGauche;
    const acuiteVisuelPres = this.examRefraction.refraction.acuitevisueldepres.oielGauche;

    // Parse value once
    let sphereLoin = parseFloat(value);

    if (sphereLoin === 0) {
      acuiteVisuelLoin.sphere = "Plan";
      acuiteVisuelPres.sphere = parseFloat(acuiteVisuelPres.add);
      if (acuiteVisuelPres.sphere > 0) {
        acuiteVisuelPres.sphere = `+${acuiteVisuelPres.sphere}`;
      } else if (acuiteVisuelPres.sphere === 0) {
        acuiteVisuelPres.sphere = "Plan";
      }
      return;
    }

    // Handle non-numeric or NaN cases
    if (isNaN(sphereLoin)) {
      acuiteVisuelLoin.sphere = value;
      return;
    }

    // Handle numeric cases
    if (sphereLoin > 0) {
      acuiteVisuelLoin.sphere = `+${sphereLoin}`;
    } else {
      acuiteVisuelLoin.sphere = sphereLoin;
    }

    acuiteVisuelPres.sphere = sphereLoin + parseFloat(acuiteVisuelPres.add);
    if (acuiteVisuelPres.sphere === 0) {
      acuiteVisuelPres.sphere = 'Plan';
    } else if (acuiteVisuelPres.sphere > 0) {
      acuiteVisuelPres.sphere = `+${acuiteVisuelPres.sphere}`;
    }
  }
  incrementLoinOGSph() {
    // Parse values as floats, initializing as 0 if parsing fails
    let acuiteVisuelLoinSphere = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielGauche.sphere) || 0;
    const add = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.add) || 0;

    // Check if the current sphere value is "Plan"
    if (this.examRefraction.refraction.acuitevisueldeloin.oielGauche.sphere === "Plan") {
      acuiteVisuelLoinSphere = 0;
    }

    if (isNaN(acuiteVisuelLoinSphere) || acuiteVisuelLoinSphere === undefined) {
      acuiteVisuelLoinSphere = 0;
    }

    acuiteVisuelLoinSphere += 0.25;
    let updatedSpherePres = acuiteVisuelLoinSphere + add;

    // Format the values
    let formattedSphereLoin;
    let formattedSpherePres;

    // Format loin sphere value
    if (acuiteVisuelLoinSphere === 0) {
      formattedSphereLoin = "Plan";
    } else {
      formattedSphereLoin = acuiteVisuelLoinSphere > 0
        ? `+${acuiteVisuelLoinSphere}`
        : `${acuiteVisuelLoinSphere}`;
    }

    // Format pres sphere value
    formattedSpherePres = updatedSpherePres === 0
      ? "Plan"
      : updatedSpherePres > 0
        ? `+${updatedSpherePres}`
        : `${updatedSpherePres}`;

    // Update the original object properties
    this.examRefraction.refraction.acuitevisueldeloin.oielGauche.sphere = formattedSphereLoin;
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere = formattedSpherePres;
  }
  decrementLoinOGSph() {
    // Convert current sphere to a float, with fallback to 0 if conversion fails
    let loinSphere = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielGauche.sphere) || 0;
    let presSphere = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere) || 0;
    const add = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.add) || 0;

    // Check if the current sphere value is "Plan" and convert it back to 0 for operations
    if (this.examRefraction.refraction.acuitevisueldeloin.oielGauche.sphere === "Plan") {
      loinSphere = 0;
    }

    // Decrement the sphere value
    loinSphere -= 0.25;

    // Update loinSphere value after decrement
    if (isNaN(loinSphere)) {
      loinSphere = 0;
    }

    // Calculate presSphere based on the updated loinSphere
    presSphere = loinSphere + add;

    // Set 'sphere' to "Plan" if it becomes exactly 0 after decrementing
    let formattedLoinSphere = loinSphere === 0 ? "Plan" : loinSphere > 0 ? `+${loinSphere}` : `${loinSphere}`;
    let formattedPresSphere = presSphere === 0 ? "Plan" : presSphere > 0 ? `+${presSphere}` : `${presSphere}`;

    // Update the original object properties
    this.examRefraction.refraction.acuitevisueldeloin.oielGauche.sphere = formattedLoinSphere;
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere = formattedPresSphere;
  }
  //todo----- end of Sphére
  //todo-----  Cylindre  
  changeLoinLoinODCy(value) {
    const acuiteVisuelLoin = this.examRefraction.refraction.acuitevisueldeloin.oielDroit;
    const acuiteVisuelPres = this.examRefraction.refraction.acuitevisueldepres.oielDroit;

    // Parse value once
    let cylindreLoin = parseFloat(value);

    if (cylindreLoin === 0) {
      acuiteVisuelLoin.cylindre = "Sph";
      acuiteVisuelPres.cylindre = parseFloat(acuiteVisuelPres.add);

      if (acuiteVisuelPres.cylindre > 0) {
        acuiteVisuelPres.cylindre = `+${acuiteVisuelPres.cylindre}`;
      } else if (acuiteVisuelPres.cylindre === 0) {
        acuiteVisuelPres.cylindre = "Sph"
      }
      return;
    }

    // Handle non-numeric or NaN cases
    if (isNaN(cylindreLoin)) {
      acuiteVisuelLoin.cylindre = value;
      return;
    }

    // Handle numeric cases
    if (cylindreLoin > 0) {
      acuiteVisuelLoin.cylindre = `+${cylindreLoin}`;
    } else {
      acuiteVisuelLoin.cylindre = cylindreLoin;
    }

    acuiteVisuelPres.cylindre = cylindreLoin + parseFloat(acuiteVisuelPres.add);
    if (acuiteVisuelPres.cylindre === 0) {
      acuiteVisuelPres.cylindre = "Sph"
    } else if (acuiteVisuelPres.cylindre > 0) {
      acuiteVisuelPres.cylindre = `+${acuiteVisuelPres.cylindre}`;
    }
  }
  incrementLoinODCy() {
    // Parse values as floats, initializing as 0 if parsing fails
    let acuiteVisuelLoincylindre = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielDroit.cylindre) || 0;
    const add = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.add) || 0;

    // Check if the current cylindre value is "Sph"
    if (this.examRefraction.refraction.acuitevisueldeloin.oielDroit.cylindre === "Sph") {
      acuiteVisuelLoincylindre = 0;
    }

    if (isNaN(acuiteVisuelLoincylindre) || acuiteVisuelLoincylindre === undefined) {
      acuiteVisuelLoincylindre = 0;
    }

    acuiteVisuelLoincylindre += 0.25;
    let updatedcylindrePres = acuiteVisuelLoincylindre + add;

    // Format the values
    let formattedcylindreLoin;
    let formattedcylindrePres;

    // Format loin cylindre value
    if (acuiteVisuelLoincylindre === 0) {
      formattedcylindreLoin = "Sph";
    } else {
      formattedcylindreLoin = acuiteVisuelLoincylindre > 0
        ? `+${acuiteVisuelLoincylindre}`
        : `${acuiteVisuelLoincylindre}`;
    }

    // Format pres cylindre value
    formattedcylindrePres = updatedcylindrePres === 0 ? "Sph" :
      updatedcylindrePres > 0
        ? `+${updatedcylindrePres}`
        : `${updatedcylindrePres}`;

    // Update the original object properties
    this.examRefraction.refraction.acuitevisueldeloin.oielDroit.cylindre = formattedcylindreLoin;
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.cylindre = formattedcylindrePres;
  }
  decrementLoinODCy() {
    // Convert current cylindre to a float, with fallback to 0 if conversion fails
    let loincylindre = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielDroit.cylindre) || 0;
    let prescylindre = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.cylindre) || 0;
    const add = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.add) || 0;

    // Check if the current cylindre value is "Sph" and convert it back to 0 for operations
    if (this.examRefraction.refraction.acuitevisueldeloin.oielDroit.cylindre === "Sph") {
      loincylindre = 0;
    }

    // Decrement the cylindre value
    loincylindre -= 0.25;

    // Update loincylindre value after decrement
    if (isNaN(loincylindre)) {
      loincylindre = 0;
    }

    // Calculate prescylindre based on the updated loincylindre
    prescylindre = loincylindre + add;

    // Set 'cylindre' to "Sph" if it becomes exactly 0 after decrementing
    let formattedLoincylindre = loincylindre === 0 ? "Sph" : loincylindre > 0 ? `+${loincylindre}` : `${loincylindre}`;
    let formattedPrescylindre = prescylindre === 0 ? "Sph" : prescylindre > 0 ? `+${prescylindre}` : `${prescylindre}`;

    // Update the original object properties
    this.examRefraction.refraction.acuitevisueldeloin.oielDroit.cylindre = formattedLoincylindre;
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.cylindre = formattedPrescylindre;
  }

  changeLoinOGCy(value) {
    const acuiteVisuelLoin = this.examRefraction.refraction.acuitevisueldeloin.oielGauche;
    const acuiteVisuelPres = this.examRefraction.refraction.acuitevisueldepres.oielGauche;

    // Parse value once
    let cylindreLoin = parseFloat(value);

    if (cylindreLoin === 0) {
      acuiteVisuelLoin.cylindre = "Sph";
      acuiteVisuelPres.cylindre = parseFloat(acuiteVisuelPres.add);

      if (acuiteVisuelPres.cylindre > 0) {
        acuiteVisuelPres.cylindre = `+${acuiteVisuelPres.cylindre}`;
      } else if (acuiteVisuelPres.cylindre === 0) {
        acuiteVisuelPres.cylindre = "Sph"
      }
      return;
    }

    // Handle non-numeric or NaN cases
    if (isNaN(cylindreLoin)) {
      acuiteVisuelLoin.cylindre = value;
      return;
    }

    // Handle numeric cases
    if (cylindreLoin > 0) {
      acuiteVisuelLoin.cylindre = `+${cylindreLoin}`;
    } else {
      acuiteVisuelLoin.cylindre = cylindreLoin;
    }

    acuiteVisuelPres.cylindre = cylindreLoin + parseFloat(acuiteVisuelPres.add);
    if (acuiteVisuelPres.cylindre === 0) {
      acuiteVisuelPres.cylindre = "Sph"
    } else if (acuiteVisuelPres.cylindre > 0) {
      acuiteVisuelPres.cylindre = `+${acuiteVisuelPres.cylindre}`;
    }
  }
  incrementLoinOGCy() {
    // Parse values as floats, initializing as 0 if parsing fails
    let acuiteVisuelLoincylindre = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielGauche.cylindre) || 0;
    const add = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.add) || 0;

    // Check if the current cylindre value is "Sph"
    if (this.examRefraction.refraction.acuitevisueldeloin.oielGauche.cylindre === "Sph") {
      acuiteVisuelLoincylindre = 0;
    }

    if (isNaN(acuiteVisuelLoincylindre) || acuiteVisuelLoincylindre === undefined) {
      acuiteVisuelLoincylindre = 0;
    }

    acuiteVisuelLoincylindre += 0.25;
    let updatedcylindrePres = acuiteVisuelLoincylindre + add;

    // Format the values
    let formattedcylindreLoin;
    let formattedcylindrePres;

    // Format loin cylindre value
    if (acuiteVisuelLoincylindre === 0) {
      formattedcylindreLoin = "Sph";
    } else {
      formattedcylindreLoin = acuiteVisuelLoincylindre > 0
        ? `+${acuiteVisuelLoincylindre}`
        : `${acuiteVisuelLoincylindre}`;
    }

    // Format pres cylindre value
    formattedcylindrePres = updatedcylindrePres === 0 ? "Sph" :
      updatedcylindrePres > 0
        ? `+${updatedcylindrePres}`
        : `${updatedcylindrePres}`;

    // Update the original object properties
    this.examRefraction.refraction.acuitevisueldeloin.oielGauche.cylindre = formattedcylindreLoin;
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.cylindre = formattedcylindrePres;
  }
  decrementLoinOGCy() {
    // Convert current cylindre to a float, with fallback to 0 if conversion fails
    let loincylindre = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielGauche.cylindre) || 0;
    let prescylindre = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.cylindre) || 0;
    const add = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.add) || 0;

    // Check if the current cylindre value is "Sph" and convert it back to 0 for operations
    if (this.examRefraction.refraction.acuitevisueldeloin.oielGauche.cylindre === "Sph") {
      loincylindre = 0;
    }

    // Decrement the cylindre value
    loincylindre -= 0.25;

    // Update loincylindre value after decrement
    if (isNaN(loincylindre)) {
      loincylindre = 0;
    }

    // Calculate prescylindre based on the updated loincylindre
    prescylindre = loincylindre + add;

    // Set 'cylindre' to "Sph" if it becomes exactly 0 after decrementing
    let formattedLoincylindre = loincylindre === 0 ? "Sph" : loincylindre > 0 ? `+${loincylindre}` : `${loincylindre}`;
    let formattedPrescylindre = prescylindre === 0 ? "Sph" : prescylindre > 0 ? `+${prescylindre}` : `${prescylindre}`;

    // Update the original object properties
    this.examRefraction.refraction.acuitevisueldeloin.oielGauche.cylindre = formattedLoincylindre;
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.cylindre = formattedPrescylindre;
  }
  //todo----- end of Cylindre  
  //todo-----  Axe  
  changeLoinODAxe(value) {
    this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe = parseFloat(value);
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe)
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe < 0) {
        this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe = 0;
        this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe) + parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.add)
      } else {
        this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe
        this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe) + parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.add)
      }
    } else {
      this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe = value;
    }
    if (isNaN(this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe)) {
      this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe = ""
    } else {
      this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe
    }
  }

  incrementLoinODAxe() {
    this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe);
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe)
    if (isNaN(this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe) || this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe === undefined) {
      this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe = 0;
    } else if (this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe >= 0) {
      this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe += 5;
    }
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe) + parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.add)
  }

  decrementLoinODAxe() {
    this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe);
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe)
    if (isNaN(this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe) || this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe === undefined) {
      this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe = 0;
    } else if (this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe <= 1) {
      this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe = 0;
    } else {
      this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe -= 5;
      if (this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe < 0) {
        this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe = 0
      }
    }
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe) + parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.add)
  }
  changeLoinOGAxe(value) {
    this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe = parseFloat(value);
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe)
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe < 0) {
        this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe = 0;
        this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe) + parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.add)
      } else {
        this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe
        this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe) + parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.add)
      }
    } else {
      this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe = value;
    }
    if (isNaN(this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe)) {
      this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe = ""
    }
    else {
      this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe
    }
  }

  incrementLoinOGAxe() {
    this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe);
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe)
    if (isNaN(this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe) || this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe === undefined) {
      this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe = 0;
    } else if (this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe >= 0) {
      this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe += 5;
    }
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe) + parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.add)
  }

  decrementLoinOGAxe() {
    this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe);
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe)
    if (isNaN(this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe) || this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe === undefined) {
      this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe = 0;
    } else if (this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe <= 1) {
      this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe = 0;
    } else {
      this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe -= 5;
      if (this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe < 0) {
        this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe = 0;
      }
    }
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe = parseFloat(this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe) + parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.add)
  }
  //todo----- end of  Axe  
  // todo---- end of Acuite visuel de loin



  // !--- Acuite visuel de Pres
  //!----- Sphére  
  changePresODSph(value) {
    // Convert the input value to a float for calculations
    let sphereValue = parseFloat(value);

    // Check if the input is a valid number and not an empty string
    if (!isNaN(sphereValue) && value !== '') {
      // Handle the scenario where the sphere value is exactly 0
      if (sphereValue === 0) {
        this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere = "Plan";
      } else {

        this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere = sphereValue > 0
          ? `+${sphereValue}`
          : `${sphereValue}`;
      }
    } else {
      this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere = value;
    }
  }
  incrementPresODSph() {
    // Parse the sphere value as a float
    let sphereValue = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere);

    // Check for NaN or undefined, and treat as 0
    if (isNaN(sphereValue) || this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere === undefined || this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere === "Plan") {
      sphereValue = 0;
    }
    // Increment the sphere value by 0.25
    sphereValue += 0.25;
    // Format the sphere value for positive numbers
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere = sphereValue === 0
      ? "Plan"
      : sphereValue > 0
        ? `+${sphereValue}`
        : `${sphereValue}`;
  }

  decrementPresODSph() {
    // Parse the sphere value as a float
    let sphereValue = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere);
    // Check for NaN or undefined, and treat as 0
    if (isNaN(sphereValue) || this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere === "Plan") {
      sphereValue = 0;
    }
    // Decrement the sphere value by 0.25
    sphereValue -= 0.25;

    // Format the sphere value
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.sphere = sphereValue === 0
      ? "Plan"
      : sphereValue > 0
        ? `+${sphereValue}`
        : `${sphereValue}`;
  }

  changePresPresOGSph(value) {
    // Convert the input value to a float for calculations
    let sphereValue = parseFloat(value);

    // Check if the input is a valid number and not an empty string
    if (!isNaN(sphereValue) && value !== '') {
      // Handle the scenario where the sphere value is exactly 0
      if (sphereValue === 0) {
        this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere = "Plan";
      } else {

        this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere = sphereValue > 0
          ? `+${sphereValue}`
          : `${sphereValue}`;
      }
    } else {
      this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere = value;
    }
  }

  incrementPresOGSph() {
    // Parse the sphere value as a float
    let sphereValue = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere);

    // Check for NaN or undefined, and treat as 0
    if (isNaN(sphereValue) || this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere === undefined || this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere === "Plan") {
      sphereValue = 0;
    }
    // Increment the sphere value by 0.25
    sphereValue += 0.25;
    // Format the sphere value for positive numbers
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere = sphereValue === 0
      ? "Plan"
      : sphereValue > 0
        ? `+${sphereValue}`
        : `${sphereValue}`;
  }

  decrementPresOGSph() {
    // Parse the sphere value as a float
    let sphereValue = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere);
    // Check for NaN or undefined, and treat as 0
    if (isNaN(sphereValue) || this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere === "Plan") {
      sphereValue = 0;
    }
    // Decrement the sphere value by 0.25
    sphereValue -= 0.25;

    // Format the sphere value
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.sphere = sphereValue === 0
      ? "Plan"
      : sphereValue > 0
        ? `+${sphereValue}`
        : `${sphereValue}`;
  }
  //!----- end of Sphére
  //!-----  Cylindre  
  changePresPresODCy(value) {
    // Convert the input value to a float for calculations
    let cylindreValue = parseFloat(value);

    // Check if the input is a valid number and not an empty string
    if (!isNaN(cylindreValue) && value !== '') {
      // Handle the scenario where the cylindre value is exactly 0
      if (cylindreValue === 0) {
        this.examRefraction.refraction.acuitevisueldepres.oielDroit.cylindre = "Sph";
      } else {

        this.examRefraction.refraction.acuitevisueldepres.oielDroit.cylindre = cylindreValue > 0
          ? `+${cylindreValue}`
          : `${cylindreValue}`;
      }
    } else {
      this.examRefraction.refraction.acuitevisueldepres.oielDroit.cylindre = value;
    }
  }

  incrementPresODCy() {
    // Parse the cylindre value as a float
    let cylindreValue = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.cylindre);

    // Check for NaN or undefined, and treat as 0
    if (isNaN(cylindreValue) || this.examRefraction.refraction.acuitevisueldepres.oielDroit.cylindre === undefined || this.examRefraction.refraction.acuitevisueldepres.oielDroit.cylindre === "Sph") {
      cylindreValue = 0;
    }
    // Increment the cylindre value by 0.25
    cylindreValue += 0.25;
    // Format the cylindre value for positive numbers
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.cylindre = cylindreValue === 0
      ? "Sph"
      : cylindreValue > 0
        ? `+${cylindreValue}`
        : `${cylindreValue}`;
  }

  decrementPresODCy() {
    // Parse the cylindre value as a float
    let cylindreValue = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.cylindre);
    // Check for NaN or undefined, and treat as 0
    if (isNaN(cylindreValue) || this.examRefraction.refraction.acuitevisueldepres.oielDroit.cylindre === "Sph") {
      cylindreValue = 0;
    }
    // Decrement the cylindre value by 0.25
    cylindreValue -= 0.25;

    // Format the cylindre value
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.cylindre = cylindreValue === 0
      ? "Sph"
      : cylindreValue > 0
        ? `+${cylindreValue}`
        : `${cylindreValue}`;
  }

  changePresOGCy(value) {
    // Convert the input value to a float for calculations
    let cylindreValue = parseFloat(value);

    // Check if the input is a valid number and not an empty string
    if (!isNaN(cylindreValue) && value !== '') {
      // Handle the scenario where the cylindre value is exactly 0
      if (cylindreValue === 0) {
        this.examRefraction.refraction.acuitevisueldepres.oielGauche.cylindre = "Sph";
      } else {

        this.examRefraction.refraction.acuitevisueldepres.oielGauche.cylindre = cylindreValue > 0
          ? `+${cylindreValue}`
          : `${cylindreValue}`;
      }
    } else {
      this.examRefraction.refraction.acuitevisueldepres.oielGauche.cylindre = value;
    }
  }
  incrementPresOGCy() {
    // Parse the cylindre value as a float
    let cylindreValue = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.cylindre);

    // Check for NaN or undefined, and treat as 0
    if (isNaN(cylindreValue) || this.examRefraction.refraction.acuitevisueldepres.oielGauche.cylindre === undefined || this.examRefraction.refraction.acuitevisueldepres.oielGauche.cylindre === "Sph") {
      cylindreValue = 0;
    }
    // Increment the cylindre value by 0.25
    cylindreValue += 0.25;
    // Format the cylindre value for positive numbers
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.cylindre = cylindreValue === 0
      ? "Sph"
      : cylindreValue > 0
        ? `+${cylindreValue}`
        : `${cylindreValue}`;
  }
  decrementPresOGCy() {
    // Parse the cylindre value as a float
    let cylindreValue = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.cylindre);
    // Check for NaN or undefined, and treat as 0
    if (isNaN(cylindreValue) || this.examRefraction.refraction.acuitevisueldepres.oielGauche.cylindre === "Sph") {
      cylindreValue = 0;
    }
    // Decrement the cylindre value by 0.25
    cylindreValue -= 0.25;

    // Format the cylindre value
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.cylindre = cylindreValue === 0
      ? "Sph"
      : cylindreValue > 0
        ? `+${cylindreValue}`
        : `${cylindreValue}`;
  }
  //!----- end of Cylindre  
  //!-----  Axe  
  changePresODAxe(value) {
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe < 0) {
        this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe = 0;
      } else {
        this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe
      }
    } else {
      this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe = value;
    }
  }

  incrementPresODAxe() {
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe)
    if (isNaN(this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe) || this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe === undefined) {
      this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe = 0;
    } else if (this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe >= 0) {
      this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe += 5;
    }
  }

  decrementPresODAxe() {
    this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe)
    if (isNaN(this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe) || this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe === undefined) {
      this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe = 0;
    } else if (this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe <= 1) {
      this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe = 0;
    } else {
      this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe -= 5;
      if (this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe < 0) {
        this.examRefraction.refraction.acuitevisueldepres.oielDroit.axe = 0;
      }
    }
  }
  changePresOGAxe(value) {
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe = parseFloat(value);
    if (!isNaN(value)) {
      if (this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe < 0) {
        this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe = 0;
      } else {
        this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe
      }
    } else {
      this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe = value;
    }
  }

  incrementPresOGAxe() {
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe)
    if (isNaN(this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe) || this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe === undefined) {
      this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe = 0;
    } else if (this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe >= 0) {
      this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe += 5;
    }
  }

  decrementPresOGAxe() {
    this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe = parseFloat(this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe)
    if (isNaN(this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe) || this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe === undefined) {
      this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe = 0;
    } else if (this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe <= 1) {
      this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe = 0;
    } else {
      this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe -= 5;
      if (this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe < 0) {
        this.examRefraction.refraction.acuitevisueldepres.oielGauche.axe = 0;
      }
    }
  }
  //!----- end of  Axe  
  //!---- end of Acuite visuel de Pres


  openCons(historique) {
    window.open(`medecin/ajout/interrogatoire/${this.patient._id}/${historique.id}`, '_blank')
  }
  displayAndHideHistorique() {
    this.displayHistorique = !this.displayHistorique
  }
  displayAndHidetodayExamen() {
    this.todayExamenRef = !this.todayExamenRef
    this.sendTodayExamToParent()
  }
  displayAndHideRefractionSubjective() {
    this.displayRefractionSubjectiveHist = !this.displayRefractionSubjectiveHist
    this.displayAcuiteVisuelleHist = false
    this.displayRefractionObjectiveHist = false
    this.isActive = 'refractionSubjective';
    this.setPage(1)
    this.p = 1
  }
  displayAndHideAcuiteVisuelleHist() {
    this.displayAcuiteVisuelleHist = !this.displayAcuiteVisuelleHist
    this.displayRefractionSubjectiveHist = false
    this.displayRefractionObjectiveHist = false
    this.isActive = 'acuiteVisuelleHist';
  }
  displayAndHideRefractionObjective() {
    this.displayRefractionObjectiveHist = !this.displayRefractionObjectiveHist
    this.displayAcuiteVisuelleHist = false
    this.displayRefractionSubjectiveHist = false
    this.isActive = 'refractionObjective';
    this.setPage(1)
    this.p3 = 1
  }
  displayAndHideAcuitevisuelleLoin() {
    this.selectedAcuiteHistoriqueLoin = true
    this.selectedAcuiteHistoriquePres = false
    this.isActiveSubMenu = "acuiteVisuelleHistLoin"
  }
  displayAndHideAcuitevisuellePres() {
    this.selectedAcuiteHistoriqueLoin = false
    this.selectedAcuiteHistoriquePres = true
    this.isActiveSubMenu = "acuiteVisuelleHistPres"
  }
  pageChanged(event: any): void {
    this.setPage(event);
  }

  setPage(page: number): void {
    this.p = page;
    const index = (page - 1) * this.itemsPerPage;
    this.paginatedList = [this.oldListConsultations[index]]; // Get the single item for the current page
    this.onDateSelect(this.oldListConsultations[index].start)
    this.onDateSelectRefOb(this.oldListConsultations[index].start)
  }


  previousPage(): void {
    if (this.p > 1) {
      this.p--;
      this.setPage(this.p);
    }
    if (this.p3 > 1) {
      this.p3--;
      this.setPage(this.p3);
    }
  }

  nextPage(): void {
    const totalPages = this.oldListConsultations.length;
    if (this.p < totalPages) {
      this.p++;
      this.setPage(this.p);
    }
    if (this.p3 < totalPages) {
      this.p3++;
      this.setPage(this.p3);
    }
  }
  getRefractionSansCycDatafromFile() {
    this.examenService.getRefractionDatafromFile().subscribe(data => {
      if (data && data.success && data.excelData) {
        // this.examRefraction.refraction.acuitevisueldeloin.oielDroit.avsc = this.correspondanceValuers(data.excelData.A9)
        // this.examRefraction.refraction.acuitevisueldeloin.oielGauche.avsc = this.correspondanceValuers(data.excelData.C9)
        this.examRefraction.refraction.acuitevisueldeloin.oielDroit.avac = this.correspondanceValuers(data.excelData.A35)
        this.examRefraction.refraction.acuitevisueldeloin.oielGauche.avac = this.correspondanceValuers(data.excelData.C35)
        data.excelData = this.addPlusToPositiveValues(data.excelData)
        this.examRefraction.refraction.sansCycloplegie.oielDroit.sphere = data.excelData.A9
        this.examRefraction.refraction.sansCycloplegie.oielDroit.cylindre = data.excelData.A10
        this.examRefraction.refraction.sansCycloplegie.oielDroit.axe = data.excelData.A11

        this.examRefraction.refraction.sansCycloplegie.oielGauche.sphere = data.excelData.C9
        this.examRefraction.refraction.sansCycloplegie.oielGauche.cylindre = data.excelData.C10
        this.examRefraction.refraction.sansCycloplegie.oielGauche.axe = data.excelData.C11

        this.examRefraction.refraction.acuitevisueldeloin.oielDroit.sphere = data.excelData.E9
        this.examRefraction.refraction.acuitevisueldeloin.oielDroit.cylindre = data.excelData.E10
        this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe = data.excelData.E11

        this.examRefraction.refraction.acuitevisueldeloin.oielGauche.sphere = data.excelData.G9
        this.examRefraction.refraction.acuitevisueldeloin.oielGauche.cylindre = data.excelData.G10
        this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe = data.excelData.G11

        this.examRefraction.refraction.acuitevisueldepres.oielDroit.add = data.excelData.E12
        this.examRefraction.refraction.acuitevisueldepres.oielGauche.add = data.excelData.G12
        if (!this.examRefraction.refraction.acuitevisueldepres.oielDroit.add || this.examRefraction.refraction.acuitevisueldepres.oielDroit.add == "NaN") {
          this.examRefraction.refraction.acuitevisueldepres.oielDroit.add = 0
        }
        if (!this.examRefraction.refraction.acuitevisueldepres.oielGauche.add || this.examRefraction.refraction.acuitevisueldepres.oielGauche.add == "NaN") {
          this.examRefraction.refraction.acuitevisueldepres.oielGauche.add = 0
        }
        this.onchangeAdddroit(this.examRefraction.refraction.acuitevisueldepres.oielDroit.add)
        this.onchangeAddGauche(this.examRefraction.refraction.acuitevisueldepres.oielGauche.add)


        return true;
      } else {
        this.iziToast.show({
          message: 'Aucun fichier trouvé!',
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
        });
        return false;

      }
    },
      err => {
        return false;
      });
  }
  getRefractionAvecCycDatafromFile() {
    this.examenService.getRefractionDatafromFile().subscribe(data => {
      if (data && data.success && data.excelData) {
        // this.examRefraction.refraction.acuitevisueldeloin.oielDroit.avsc = this.correspondanceValuers(data.excelData.A9)
        // this.examRefraction.refraction.acuitevisueldeloin.oielGauche.avsc = this.correspondanceValuers(data.excelData.C9)
        this.examRefraction.refraction.acuitevisueldeloin.oielDroit.avac = this.correspondanceValuers(data.excelData.A35)
        this.examRefraction.refraction.acuitevisueldeloin.oielGauche.avac = this.correspondanceValuers(data.excelData.C35)
        data.excelData = this.addPlusToPositiveValues(data.excelData)
        this.examRefraction.refraction.avecCycloplegie.oielDroit.sphere = data.excelData.A9
        this.examRefraction.refraction.avecCycloplegie.oielDroit.cylindre = data.excelData.A10
        this.examRefraction.refraction.avecCycloplegie.oielDroit.axe = data.excelData.A11

        this.examRefraction.refraction.avecCycloplegie.oielGauche.sphere = data.excelData.C9
        this.examRefraction.refraction.avecCycloplegie.oielGauche.cylindre = data.excelData.C10
        this.examRefraction.refraction.avecCycloplegie.oielGauche.axe = data.excelData.C11

        this.examRefraction.refraction.acuitevisueldeloin.oielDroit.sphere = data.excelData.E9
        this.examRefraction.refraction.acuitevisueldeloin.oielDroit.cylindre = data.excelData.E10
        this.examRefraction.refraction.acuitevisueldeloin.oielDroit.axe = data.excelData.E11

        this.examRefraction.refraction.acuitevisueldeloin.oielGauche.sphere = data.excelData.G9
        this.examRefraction.refraction.acuitevisueldeloin.oielGauche.cylindre = data.excelData.G10
        this.examRefraction.refraction.acuitevisueldeloin.oielGauche.axe = data.excelData.G11

        this.examRefraction.refraction.acuitevisueldepres.oielDroit.add = data.excelData.E12
        this.examRefraction.refraction.acuitevisueldepres.oielGauche.add = data.excelData.G12
        if (!this.examRefraction.refraction.acuitevisueldepres.oielDroit.add || this.examRefraction.refraction.acuitevisueldepres.oielDroit.add == "NaN") {
          this.examRefraction.refraction.acuitevisueldepres.oielDroit.add = 0
        }
        if (!this.examRefraction.refraction.acuitevisueldepres.oielGauche.add || this.examRefraction.refraction.acuitevisueldepres.oielGauche.add == "NaN") {
          this.examRefraction.refraction.acuitevisueldepres.oielGauche.add = 0
        }
        this.onchangeAdddroit(this.examRefraction.refraction.acuitevisueldepres.oielDroit.add)
        this.onchangeAddGauche(this.examRefraction.refraction.acuitevisueldepres.oielGauche.add)


        return true;
      } else {
        this.iziToast.show({
          message: 'Aucun fichier trouvé!',
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
        });
        return false;

      }
    },
      err => {
        return false;
      });
  }
  // Add '+' sign to positive numerical values 
  addPlusToPositiveValues(obj) {
    // Iterate through each property in the object
    for (let key in obj) {
      // Check if the value is a positive number
      if (!isNaN(obj[key]) && Number(obj[key]) > 0) {
        // Check if value already has a '+' sign
        if (!obj[key].startsWith('+')) {
          // Add '+' sign to positive value
          obj[key] = "+" + obj[key];
        }
      }
    }
    return obj;
  }
  // correspondance des valuers 
  correspondanceValuers(x) {
    if (x === "2" || x === "2.0" || x === "2.00") {
      x = "10/10"
    }
    if (x === "1.8") {
      x = "9/10"
    }
    if (x === "1.6") {
      x = "8/10"
    }
    if (x === "1.4") {
      x = "7/10"
    }
    if (x === "1.2") {
      x = "6/10"
    }
    if (x === "1" || x === "1.0" || x === "1.00") {
      x = "5/10"
    }
    if (x === "0.8") {
      x = "4/10"
    }
    if (x === "0.6") {
      x = "3/10"
    }
    if (x === "0.4") {
      x = "2/10"
    }
    if (x === "0.2") {
      x = "1/10"
    }
    return x

  }
}
